<template>
    <uw-content title="通知公告" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">添加信息</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <!-- 类型 -->
                <news-type-select @onChange="TableSearch" v-model="search.type_id" width="200px" margin="0 5px 0 0" />
                <!-- 标题 -->
                <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="标题检索"></el-input>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <!-- table -->
                <vxe-table ref="xTable" height="100%" :data="table" :loading="loading" @sort-change="TableSort" :export-config="{}" :print-config="{}">

                    <!-- ID -->
                    <vxe-column width="60" field="id" title="ID" sortable></vxe-column>

                    <!-- 标题 -->
                    <vxe-column min-width="200" field="name" title="标题">
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerWindow.Open(row.id)">
                                <i v-if="row.files_id.length > 0" class="el-icon-paperclip"></i>
                                {{ row.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 类型 -->
                    <vxe-column width="100" field="type" title="类型"></vxe-column>

                    <!-- 已阅读 -->
                    <vxe-column width="80" field="users_read_id" title="已阅读">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-user">{{ row.users_read_id?.length ?? 0 }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 创建人 -->
                    <vxe-column width="120" title="创建人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.users_admin?.avatar"> {{ row.users_admin?.name[0] }} </el-avatar>
                                {{  row.users_admin?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 日期 -->
                    <vxe-column width="140" field="updated_at" title="更新日期"></vxe-column>
                    <vxe-column width="140" field="created_at" title="创建日期"></vxe-column>

                    <vxe-column width="90" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-link :underline="false" type="primary" class="w-margin-r-5" @click="$refs.drawerUpdate.Open(row.id)">编辑</el-link>
                            <el-link :underline="false" type="danger" @click="TableDelete(row.id)">删除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <news-create ref="drawerCreate" @onChange="TableSearch" />
        <news-update ref="drawerUpdate" @onChange="TableSearch" />
        <news-window ref="drawerWindow" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                type_id: null,
                name: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/news/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['users_admin', 'type'],
                    hidden: ['describe']

                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$confirm('数据删除后不可恢复！', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/news/delete', { id: id }).then(rps => {
                    this.TableSearch()
                })
            })
        },
    }
}
</script>